import React, { useState } from 'react';
import Indicators from './Indicators';
import DashboardTable from '../components/table/DashboardTable';
import {
  Backdrop,
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';

function Dashboard() {
  // const [triggerRerender, setTriggerRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));
  
  return (
    <>
      {matches && (
        <h3 style={{ textAlign: 'center' }}>
          content only available on Desktop mode
        </h3>
      )}
      {/* <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color='primary' />
      </Backdrop> */}
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <Indicators isLoading={isLoading} />
        <DashboardTable
          // triggerRerender={triggerRerender}
          // setTriggerRerender={setTriggerRerender}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Box>
    </>
  );
}

export default Dashboard;
