import { format, parseISO } from 'date-fns';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// A Function that passes the data object to a more readable format
export function dateConverter(dateInput) {
  const dateObject = parseISO(dateInput);
  const formattedDate = format(dateObject, 'dd-MM-yyyy');
  return formattedDate;
}

export function getStaffDetails() {
  const result = JSON.parse(localStorage.getItem('trdUser'));
  const name = result.email.split('@')[0];
  const email = result.email;
  return { name, email };
}
