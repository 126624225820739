import { createTheme } from '@mui/material';

const trdRed = '#E97D80';
const trdBlue = '#231F20';
const trdGrey = '#454E68';
const trdPurple = '#B3B3D8';
const trdGreen = '#A5D6A7';
const trdDarkGreen = '#1B5E20';
const trdPink = '#FFD8E4';
const trdYellow = '#FFD300';

export const theme = createTheme({
  palette: {
    common: {
      red: `${trdRed}`,
      blue: `${trdBlue}`,
      purple: `${trdPurple}`,
      green: `${trdGreen}`,
      darkGreen: `${trdDarkGreen}`,
      pink: `${trdPink}`,
      grey: `${trdGrey}`,
      yellow: `${trdYellow}`,
    },
    primary: {
      main: `${trdRed}`,
    },
    secondary: {
      main: `${trdBlue}`,
    },
    accent: {
      main: `${trdPurple}`,
    },
    yellow: {
      main: `${trdYellow}`,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Poppins", "sans-serif"].join(","),
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
});
