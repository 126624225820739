import React from 'react';
import Input from './Input';

function FormControl({ control, ...rest }) {
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    default:
      return null;
  }
}

export default FormControl;
