import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { logoutUser } from "../features/trdUserSlice";
import customFetch from "../utils/axios";
import FormControl from "./form/FormControl";

function CancelledOrReturnedForm({
  currentID,
  onClose,
  status,
  setIsLoading,
  isLoading,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [action, setAction] = useState(
    status === "cancelled" ? "reasonForCancellation" : "reasonForReturn"
  );
  const initialValues = {
    [action]: "",
  };
  const validationSchema = Yup.object().shape({
    [action]: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    const actionToPerform = values[action];
    let url = `/requests/${currentID}/`;
    try {
      const resp = customFetch.put(url, {
        [action]: actionToPerform,
        status: status,
      });
      onClose();
      setIsLoading(true);
    } catch (error) {
      if (
        error.response.detail ===
        "Authentication credentials were not provided."
      ) {
        setIsLoading(false);
        dispatch(logoutUser());
        navigate("/login");
        return toast.error("Unauthorized! Logging Out...");
      }
      setIsLoading(false);
      dispatch(logoutUser());
      navigate("/login");
      toast.error("An error occurred");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { dirty, isValid } = formik;

        return (
          <Form>
            <Box>
              <FormControl
                control="input"
                label={
                  status === "cancelled"
                    ? "Reason for cancellation"
                    : "Reason for return"
                }
                name={action}
              />
            </Box>
            <Box sx={{ marginTop: "25px" }}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!dirty || !isValid || formik.isSubmitting}
                sx={{
                  "&.MuiButton-root": {
                    textTransform: "Capitalize",
                    borderRadius: "10px",
                    display: "block",
                    marginLeft: "auto",
                  },
                }}
              >
                {formik.isSubmitting ? <CircularProgress /> : "Update"}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CancelledOrReturnedForm;
