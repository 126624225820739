import { Box } from '@mui/material';
import React from 'react';

function TextError({ children }) {
  return (
    <Box
      sx={{
        color: 'red',
      }}
    >
      {children}
    </Box>
  );
}

export default TextError;
