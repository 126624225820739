import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import LoginPhoto from '../assets/LoginPhoto.svg';
import SignUpForm from '../components/SignUpForm';

function SignUp() {
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
 <>
  {matches && (
        <h3 style={{ textAlign: 'center' }}>
          content only available on Desktop mode
        </h3>
      )}
 <Box  
 
 sx={{
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },}}>
      <Container>
        <Grid
          container
          alignItems={'center'}
          columnSpacing={3}
          sx={{
           
            marginTop: 0,
          }}
        >
          <Grid item xs={6}>
            <Box
              sx={{
        

                width: '100%',
                margin: '20px auto',
              }}
            >
              <Box
                component={'img'}
                src={LoginPhoto}
                alt='Delivery Bike'
                sx={{
                  width: '100%',
                  maxHeight: '600px',
                  minHeight: '400px',
                  display: 'block',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                px: 3,
               
              }}
            >
              <Typography
                component='h3'
                color='initial'
                sx={{
                  fontSize: '57px',
                  fontWeight: '500',
                  // border: '2px solid red'
                }}
              >
                Sign-Up
              </Typography>
              <Typography
                variant='h5'
                sx={{
                  color: '#9092A3',
                }}
              >
                Access your Dashboard and Tools
              </Typography>
              <SignUpForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
 </>
  );
}

export default SignUp;
