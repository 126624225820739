import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import LoginPhoto from '../assets/LoginPhoto.svg';
import LoginForm from '../components/LoginForm';

function Login() {
  const theme = useTheme();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <>
      {matches && (
        <h3 style={{ textAlign: 'center' }}>
          content only available on Desktop mode
        </h3>
      )}
      <Box  sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}>
        <Container>
          <Grid
            container
            alignItems={'center'}
            columnSpacing={3}
            sx={{
              // border: '2px solid green',
              marginTop: 0,
            }}
          >
            <Grid item xs={6}>
              <Box
                sx={{
                  // border: '2px solid yellow',

                  width: '100%',
                  margin: '20px auto',
                }}
              >
                <Box
                  component={'img'}
                  src={LoginPhoto}
                  alt='Delivery Bike'
                  sx={{
                    width: '100%',
                    maxHeight: '700px',
                    minHeight: '400px',
                    display: 'block',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  px: 3,
                  // border: '2px solid red',
                }}
              >
                <Typography
                  component='h3'
                  color='initial'
                  sx={{
                    fontSize: '57px',
                    fontWeight: '500',
                    // border: '2px solid red'
                  }}
                >
                  Login
                </Typography>
                <Typography
                  variant='h5'
                  sx={{
                    color: '#9092A3',
                  }}
                >
                  Access your Dashboard and Tools
                </Typography>
                <LoginForm />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Login;
