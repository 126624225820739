import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import { Box, Container } from '@mui/material';

const SharedLayout = () => {
  return (
    <>
      <Navbar />
      <Box>
        <Container>
          <Outlet />
        </Container>
      </Box>
    </>
  );
};

export default SharedLayout;
