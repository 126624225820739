import { Chip, useTheme } from "@mui/material";
import React from "react";

function CustomChip({ label }) {
  const theme = useTheme();
  switch (label) {
    case "New request":
      return (
        <Chip
          label="New request"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.purple}`,
              color: `${theme.palette.common.blue}`,
              fontWeight: 500,

              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Cancelled":
      return (
        <Chip
          label="Cancelled"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.red}`,
              color: `${theme.palette.common.blue}`,
              fontWeight: 500,

              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );

    case "Out for delivery":
      return (
        <Chip
          label="Out for delivery"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.yellow}`,
              color: `${theme.palette.common.blue}`,
              fontWeight: 500,

              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );

    case "Delivered":
      return (
        <Chip
          label="Delivered"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.green}`,
              color: `${theme.palette.common.darkGreen}`,
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );
    case "Returned":
      return (
        <Chip
          label="Returned"
          sx={{
            "&.MuiChip-root": {
              background: `${theme.palette.common.grey}`,
              color: "white",
              fontWeight: 500,
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        />
      );

    default:
      return null;
  }
}

export default CustomChip;
