import { Box, Button, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FormControl from './form/FormControl';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutUser } from '../features/trdUserSlice';
import customFetch from '../utils/axios';
function OutForDeliveryForm({
  setTriggerRerender,
  currentID,
  onClose,
  setIsLoading,
  isLoading,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    terminalSerialNumber: '',
  };

  const validationSchema = Yup.object().shape({
    terminalSerialNumber: Yup.string().required('Required'),
    // nameOfStaff: Yup.string()
    //   .matches(/^[a-zA-Z]+$/, 'Name of staff should contain only letters')
    //   .required(),
    // emailOfStaff: Yup.string()
    //   .matches(
    //     /^([a-zA-Z]{0,}\.?[a-zA-Z]{1,})@(dukka.com|dipfellows.org)$/,
    //     'Email should be a valid Dukka email Address'
    //   )
    //   .required('Required'),
  });
  const onSubmit = (values) => {
    const { terminalSerialNumber } = values;
    let url = `/requests/${currentID}/`;
    console.log(values);
    try {
      const resp = customFetch.put(url, {
        terminal_serial_no: terminalSerialNumber,
        status: 'out_for_delivery',
      });
      onClose();
      setIsLoading(true);
    } catch (error) {
      if (
        error.response.detail ===
        'Authentication credentials were not provided.'
      ) {
        setIsLoading(false);
        dispatch(logoutUser());
        navigate('/login');
        return toast.error('Unauthorized! Logging Out...');
      }
      setIsLoading(false);
      dispatch(logoutUser());
      navigate('/login');
      toast.error('An error occurred');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { dirty, isValid } = formik;
        console.log(formik);

        return (
          <Form>
            <Box>
              <FormControl
                control='input'
                label='Terminal serial number'
                name='terminalSerialNumber'
              />
            </Box>
            <Box
              sx={{
                marginTop: '25px',
              }}
            >
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                disabled={!dirty || !isValid || formik.isSubmitting}
                sx={{
                  '&.MuiButton-root': {
                    textTransform: 'Capitalize',
                    borderRadius: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                  },
                }}
              >
                {' '}
                {formik.isSubmitting ? <CircularProgress /> : 'Update'}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OutForDeliveryForm;
