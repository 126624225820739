import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItemButton,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "../../features/trdUserSlice";
import customFetch from "../../utils/axios";
import { capitalizeFirstLetter, dateConverter } from "../../utils/functionUtil";
import CustomChip from "../Chip";
import Row from "./DashboardTableRow";

const columns = [
  { id: "sn", label: " ", minWidth: 50, align: "left" },
  { id: "merchantName", label: "Merchant Name", align: "left" },
  { id: "businessName", label: "Business Name", align: "left" },
  { id: "businessCategory", label: "Business category", align: "left" },
  { id: "phoneNumber", label: "Phone number", align: "left" },
  { id: "referralCode", label: "Referral code", align: "center" },
  { id: "requestStatus", label: "Request Status", align: "left" },
];

const columnsExtra = [
  { id: "status", label: "Status", minWidth: 200, align: "center" },
  { id: "address", label: "Address", minWidth: 200, align: "left" },
  {
    id: "statusCreatedDate",
    label: "Date created",
    minWidth: 130,
    align: "left",
  },
  {
    id: "statusUpdateDate",
    label: "Date updated",
    minWidth: 100,
    align: "left",
  },
  {
    id: "statusUpdatedBy",
    label: "Updated by",
    minWidth: 200,
    align: "center",
  },
  {
    id: "terminalSerialNo",
    label: "Terminal serial no",
    minWidth: 200,
    align: "left",
  },
];

function createData(
  sn,
  merchantName,
  businessName,
  businessCategory,
  phoneNumber,
  referralCode,
  requestStatus,
  address,
  statusCreatedDate,
  statusUpdateDate,
  statusUpdatedBy,
  terminalSerialNumber
) {
  return {
    sn,
    merchantName,
    businessName,
    businessCategory,
    phoneNumber,
    referralCode,
    requestStatus,
    extraDetails: [
      {
        status: requestStatus,
        address: address,
        statusCreatedDate: statusCreatedDate,
        statusUpdateDate: statusUpdateDate,
        statusUpdatedBy: statusUpdatedBy,
        terminalSerialNo: terminalSerialNumber,
      },
    ],
  };
}

function DashboardTable({ isLoading, setIsLoading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [localSearch, setLocalSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const [requestStatus, setRequestStatus] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [allRequests, setAllRequests] = useState([]);

  const currentID = useRef("");
  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;
  let url = `/requests/?page_size=${rowsPerPage}&page=${page + 1}`;

  const handleLocalSearchChange = (e) => {
    setLocalSearch(e.target.value);
    setPage(0);
    setRequestStatus("");
  };

  const handleFilterClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleFilterClose = (e) => {
    setAnchorEl(null);
  };
  const handleFilterChange = (e, filterInput) => {
    setPage(0);
    setIsLoading(true);
    setRequestStatus(filterInput);
    handleFilterClose(e);
  };
  const handleChangePage = (event, newPage) => {
    setIsLoading(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    setRowsPerPage(+event.target.value);
    setIsLoading(true);
    setPage(0);
  };

  const getAllRequests = async () => {
    try {
      setIsLoading(true);
      if (localSearch) {
        url = url + `&search=${localSearch}`;
      }
      if (requestStatus) {
        url = url + `&status=${requestStatus}`;
      }
      const resp = await customFetch.get(url);
      console.log("first", resp.data.results);

      const newData = resp.data.results.map((request, index) => {
        const {
          id,
          merchant_address,
          merchant_business_category,
          merchant_business_name,
          merchant_name,
          merchant_phone_number,
          referral_code,
          status,
          terminal_serial_no,
          updated_at,
          updated_by,
          created_at,
        } = request;

        return createData(
          id,
          merchant_name,
          merchant_business_name,
          merchant_business_category,
          merchant_phone_number,
          referral_code,
          status === "new_request"
            ? "New request"
            : status === "out_for_delivery"
            ? "Out for delivery"
            : capitalizeFirstLetter(status),
          merchant_address,
          dateConverter(created_at),
          dateConverter(updated_at),

          updated_by,
          terminal_serial_no
        );
      });
      setAllRequests(newData);
      setTotalCount(resp.data.count);
      setIsLoading(false);
      // setTriggerRerender(
      //   'Heyyy' +
      //     Math.floor(Math.random() * 100) +
      //     Math.floor(Math.random() * 100) +
      //     Math.floor(Math.random() * 100)
      // );
    } catch (error) {
      if (
        error.response.detail ===
        "Authentication credentials were not provided."
      ) {
        dispatch(logoutUser());
        navigate("/login");
        setIsLoading(false);
        return toast.error("Unauthorized! Logging Out...");
      } else {
        dispatch(logoutUser());
        navigate("/login");
        setIsLoading(false);
        toast.error("Something went wrong!");
      }
    }
  };
  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (isLoading) {
        getAllRequests();
      }
    }, 750);
    return () => clearTimeout(debounceId);
  }, [localSearch, page, requestStatus, isLoading]);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      getAllRequests();
    }, 750);
    return () => clearTimeout(debounceId);
  }, [localSearch, page, requestStatus]);

  // Displays backdrop when fetching data
  if (isLoading) {
    return (
      <Backdrop
        open={isLoading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <>
      <Box
        sx={{
          marginTop: "70px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginBottom: "30px",
          }}
        >
          <Paper elevation={1}>
            <Stack
              direction="row"
              alignItems="center"
              //   justifyContent='space-between'
              px={4}
              py={1.8}
            >
              <TextField
                id="localSearch"
                color="accent"
                value={localSearch}
                onChange={handleLocalSearchChange}
                sx={{
                  "& .MuiTextField-root": {
                    width: 200,
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "39px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  placeholder: "Search by name",
                }}
              />
              <Button
                variant="outlined"
                startIcon={<FilterListIcon />}
                color={"accent"}
                aria-describedby={id}
                onClick={handleFilterClick}
                sx={{
                  marginLeft: "30px",
                }}
              >
                Filter
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box
                  sx={{
                    width: "200px",
                  }}
                >
                  <List>
                    <ListItemButton onClick={(e) => handleFilterChange(e, "")}>
                      <span
                        style={{
                          display: "block",
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          fontWeight: 600,
                        }}
                      >
                        All
                      </span>
                    </ListItemButton>
                    <ListItemButton
                      onClick={(e) => handleFilterChange(e, "new_request")}
                    >
                      <CustomChip label="New request" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={(e) => handleFilterChange(e, "out_for_delivery")}
                    >
                      <CustomChip label="Out for delivery" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={(e) => handleFilterChange(e, "delivered")}
                    >
                      <CustomChip label="Delivered" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={(e) => handleFilterChange(e, "cancelled")}
                    >
                      <CustomChip label="Cancelled" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={(e) => handleFilterChange(e, "returned")}
                    >
                      <CustomChip label="Returned" />
                    </ListItemButton>
                  </List>
                </Box>
              </Popover>
            </Stack>
          </Paper>
        </Box>

        {/* Table Component */}
        {allRequests && (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: "#EFF1F9",
                      },
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "#2A2A44",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allRequests.map((row, index) => (
                    <Row
                      setIsLoading={setIsLoading}
                      isLoading={isLoading}
                      key={index}
                      row={row}
                      columns={columns}
                      index={index}
                      columnsExtra={columnsExtra}
                      currentID={currentID}
                      // setTriggerRerender={setTriggerRerender}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={0 || totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
}

export default DashboardTable;
