import React from 'react';
import downPointer from '../../assets/DownPointer.svg';

function DownPointer() {
  return (
    <>
    
      <img src={downPointer} alt='Down Pointer icon' />
    </>
  );
}

export default DownPointer;
