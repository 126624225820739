import { Box, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import TextError from './TextError';

function Input({ label, name, ...rest }) {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }) => (
          <>
            <Box
              component='label'
              htmlFor={name}
              sx={{
                display: 'block',
                py: 1,
              }}
            >
              <Typography
                component={'span'}
                sx={{
                  // color: 'common.white',
                  fontWeight: 500,
                  color: 'common.grey',
                }}
              >
                {label}
              </Typography>
            </Box>
            <TextField
              error={form.errors[name] && form.touched[name]}
              color='secondary'
              id={name}
              {...rest}
              {...field}
              fullWidth
            />
            <ErrorMessage component={TextError} name={name} />
          </>
        )}
      </Field>
    </Box>
  );
}

export default Input;
