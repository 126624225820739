import { ThemeProvider } from '@mui/material';
import TRDRoutes from './utils/TRDRoutes';
import { theme } from './utils/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <TRDRoutes />
    </ThemeProvider>
  );
}

export default App;
