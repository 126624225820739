import React, { useEffect, useState } from 'react';
import newRequestLogo from '../assets/newRequestLogo.svg';
import outForDeliveryLogo from '../assets/outForDeliveryLogo.svg';
import totalDeliveredLogo from '../assets/totalDeliveredLogo.svg';
import { Grid } from '@mui/material';
import IndicatorCard from '../components/IndicatorCard';
import customFetch from '../utils/axios';
import { toast } from 'react-toastify';

function Indicators({ isLoading }) {
  const [indicatorsData, setIndicatorsData] = useState([]);
  let totalRequestUrl = '/requests/totals/';

  const getAllRequest = async () => {
    try {
      const resp = await customFetch.get(totalRequestUrl);
      console.log(resp.data);
      setIndicatorsData([
        {
          id: 1,
          image: newRequestLogo,
          total: resp.data.totalNewRequests,
          bgColor: '#E8DEF8',
          text: 'Total new request',
        },
        {
          id: 2,
          image: outForDeliveryLogo,
          total: resp.data.totalOutForDelivery,
          bgColor: '#FFD8E4',
          text: 'Total out for delivery',
        },
        {
          id: 3,
          image: totalDeliveredLogo,
          total: resp.data.totalDelivered,
          bgColor: '#A5D6A7',
          text: 'Total delivered',
        },
      ]);
    } catch (error) {
      toast.error('An Error occurred in fetching the Indicators data');
    }
  };
  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        getAllRequest();
      }, 1500);
    }
  }, [isLoading]);

  return (
    <>
      <Grid
        container
        alignItems={'center'}
        sx={{
          // border: '2px solid blue',
          marginTop: '60px',
        }}
      >
        {indicatorsData.map((data) => {
          const { id, image, total, bgColor, text } = data;
          return (
            <Grid
              item
              xs={6}
              md={3.5}
              key={id}
              sx={{
                maxWidth: '400px',
                marginRight: '40px',
                //   border: '2px solid orange',
              }}
            >
              <IndicatorCard
                src={image}
                total={total}
                bgColor={bgColor}
                text={text}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default Indicators;
