import React from 'react';
import upPointer from '../../assets/UpPointer.svg';

function UpPointer() {
  return (
    <>
      <img src={upPointer} alt='Up Pointer icon' />
    </>
  );
}

export default UpPointer;
