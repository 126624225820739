import { Box, Button, CircularProgress } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { logoutUser } from '../features/trdUserSlice';
import customFetch from '../utils/axios';
import FormControl from './form/FormControl';

function DeliveredForm({

  currentID,
  onClose,
  terminalSerialNumber,
  setIsLoading,
  isLoading
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    terminalSerialnumber: terminalSerialNumber || '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z]+$/,
        'First name of recipient should contain only letters'
      )
      .required('Required'),
    lastName: Yup.string().matches(
      /^[a-zA-Z]+$/,
      'Last name of recipient should contain only letters'
    ),
    phoneNumber: Yup.string()
      .matches(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Not a valid number')
      .required('Required'),
  });

  const onSubmit = (values) => {
    const { terminalSerialNumber, firstName, lastName, phoneNumber } = values;
    let url = `/requests/${currentID}/`;
    try {
    
      const resp = customFetch.put(url, {
        terminal_serial_no: terminalSerialNumber,
        status: 'delivered',
        recipient_first_name: firstName,
        recipient_last_name: lastName,
        recipient_phone_number: phoneNumber,
      });
      onClose();
      // setTriggerRerender(
      //   'Render delivered form' +
      //     currentID +
      //     Math.floor(Math.random() * 100) +
      //     Math.floor(Math.random() * 100) +
      //     Math.floor(Math.random() * 100) +
      //     Math.floor(Math.random() * 100)
      // );
      setIsLoading(true)
    } catch (error) {
      if (
        error.response.detail ===
        'Authentication credentials were not provided.'
      ) {
        setIsLoading(false);
        dispatch(logoutUser());
        navigate('/login');
        return toast.error('Unauthorized! Logging Out...');
      }
      setIsLoading(false);
      dispatch(logoutUser());
      navigate('/login');
      toast.error('An error occurred');
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { dirty, isValid } = formik;
    

        return (
          <Form>
            <Box>
              <FormControl
                control='input'
                label='First name of recipient'
                name='firstName'
              />
              <FormControl
                control='input'
                label={'Last name of recipient (optional)'}
                name='lastName'
              />
              <FormControl
                control='input'
                label={'Phone number of recipient'}
                name='phoneNumber'
              />
            </Box>
            <Box
              sx={{
                marginTop: '25px',
              }}
            >
              <Button
                variant='contained'
                color='secondary'
                type='submit'
                disabled={!dirty || !isValid || formik.isSubmitting}
                sx={{
                  '&.MuiButton-root': {
                    textTransform: 'Capitalize',
                    borderRadius: '10px',
                    display: 'block',
                    marginLeft: 'auto',
                  },
                }}
              >
                {' '}
                {formik.isSubmitting ? <CircularProgress /> : 'Update'}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DeliveredForm;
