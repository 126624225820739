import { Box, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

function IndicatorCard({ src, total, bgColor, text }) {
  const theme = useTheme();

  return (
    <>
      <Paper elevation={3}
        sx={{
          borderRadius: '13px',
          px: 2,
          py: 2,
        }}
      >
        <Grid
          container
          alignItems={'center'}
          justifyContent='space-between'
          sx={
            {
              // border: '2px solid black',
            }
          }
        >
          <Grid item xs={12} sm={6} md={12} lg={3}>
            <Box component={'span'}>
              <Box
                component={'img'}
                src={src}
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },

                  border: '20px solid transparent',
                  display: 'block',
                  height: '2.7rem',
                  width: '2.7rem',
                  overflow: 'visible',

                  // objectFit: 'cover',
                  // padding: '10px',
                  borderRadius: '50%',
                  background: `${bgColor}`,
                  //   width: '99%',
                  margin: '10px 0',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={6.9}>
            <Stack
              sx={
                {
                  // border: '2px solid blue',
                }
              }
            >
              <Typography
                variant='body1'
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    textAlign: 'center',
                  },
                  color: '#ACACAC',
                  fontWeight: 500,
                }}
              >
                {text}
              </Typography>
              <Typography
                variant='h4'
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    textAlign: 'center',
                  },
                  fontWeight: 500,
                }}
              >
                {total}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default IndicatorCard;
