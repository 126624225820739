import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomChip from "../Chip";
import DeliveredForm from "../DeliveredForm";
import OutForDeliveryForm from "../OutForDeliveryForm";
import DownPointer from "../svg/DownPointer";
import UpPointer from "../svg/UpPointer";
import CancelledOrReturnedForm from "../CancelledOrReturnedForm.js";

function Row({
  row,
  columns,
  index,
  columnsExtra,
  currentID,
  setTriggerRerender,
  isLoading,
  setIsLoading,
}) {
  const { merchantName, businessName } = row;
  const [openTableDetails, setOpenTableDetails] = useState(false);
  const [tableAnchorEl, setTableAnchorEl] = useState(null);
  const openTableAnchorEl = Boolean(tableAnchorEl);
  const tableID = openTableAnchorEl ? "table-popover" : undefined;
  const [showOutForDeliveryForm, setShowOutForDeliveryForm] = useState(false);
  const [showDeliveredForm, setShowDeliveredForm] = useState(false);

  const [showCancelledOrReturnedForm, setShowCancelledOrReturnedForm] =
    useState(false);
  const [formStatusText, setFormStatusText] = useState("");
  const [formAction, setFormAction] = useState("cancelled");
  console.log("first", formAction);

  const [updateStatus, setUpdateStatus] = useState(false);

  const handlePopClick = (e) => {
    setTableAnchorEl(e.currentTarget);
  };
  const handlePopClose = (e) => {
    setTableAnchorEl(null);
  };
  const handlePopChange = (e, popInput) => {
    handlePopClose(e);
  };
  const handleUpdateStatusDialogOpen = () => {
    setUpdateStatus(true);
  };

  const handleUpdateStatusDialogClose = () => {
    setUpdateStatus(false);
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={merchantName + businessName + index}
        onClick={() => {
          // this changes the useRef current value to the row serial number value
          currentID.current = row.sn;
        }}
      >
        {columns.map((column) => {
          const value = row[column.id];

          return (
            <TableCell key={column.id} align={column.align}>
              {column.id === "sn" && openTableDetails === false ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenTableDetails(!openTableDetails)}
                >
                  <UpPointer />
                </IconButton>
              ) : column.id === "sn" && openTableDetails === true ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenTableDetails(!openTableDetails)}
                >
                  <DownPointer />
                </IconButton>
              ) : value === "New request" ? (
                <CustomChip label="New request" />
              ) : value === "Out for delivery" ? (
                <CustomChip label="Out for delivery" />
              ) : value === "Delivered" ? (
                <CustomChip label="Delivered" />
              ) : value === "Returned" ? (
                <CustomChip label="Returned" />
              ) : value === "Cancelled" ? (
                <CustomChip label="Cancelled" />
              ) : value === null ? (
                " - "
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={openTableDetails} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="more table details">
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        backgroundColor: `#231F20`,
                      },
                    }}
                  >
                    {columnsExtra.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "#FFFFFF",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {columnsExtra.map((column) => {
                      const value = row.extraDetails[0][column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <>
                              <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<ArrowDropDownIcon />}
                                sx={{
                                  fontWeight: 400,
                                }}
                                aria-describedby={tableID}
                                onClick={handlePopClick}
                                // disabled={value === 'Delivered'}
                              >
                                Change Status
                              </Button>
                              <Popover
                                id={tableID}
                                open={openTableAnchorEl}
                                anchorEl={tableAnchorEl}
                                onClose={handlePopClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "200px",
                                  }}
                                >
                                  <List>
                                    {value === "Delivered" && (
                                      <ListItemButton
                                        onClick={(e) => {
                                          handlePopChange(e, "I am Dami");
                                          handleUpdateStatusDialogOpen();
                                          setShowDeliveredForm(false);
                                          setShowOutForDeliveryForm(false);
                                          setFormStatusText("Returned");
                                          setFormAction("returned");
                                          setShowCancelledOrReturnedForm(true);
                                        }}
                                      >
                                        <CustomChip label="Returned" />
                                      </ListItemButton>
                                    )}
                                    {value === "New request" && (
                                      <List>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e, "I am Dami");
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(true);
                                            setFormStatusText(
                                              "Out for delivery"
                                            );
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                          }}
                                        >
                                          <CustomChip label="Out for delivery" />
                                        </ListItemButton>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e, "I am Dami");
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(false);
                                            setFormAction("cancelled");
                                            setShowCancelledOrReturnedForm(
                                              true
                                            );
                                            setFormStatusText("Cancelled");
                                          }}
                                        >
                                          <CustomChip label="Cancelled" />
                                        </ListItemButton>
                                      </List>
                                    )}
                                    {value === "Out for delivery" && (
                                      <>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e, "I am Dami");
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(true);
                                            setShowOutForDeliveryForm(false);
                                            setFormStatusText("Delivered");
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                            // setShowOutForDeliveryForm(false);
                                          }}
                                        >
                                          <CustomChip label="Delivered" />
                                        </ListItemButton>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e, "I am Dami");
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(false);
                                            setFormStatusText("Returned");
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                            // setShowOutForDeliveryForm(false);
                                          }}
                                        >
                                          <CustomChip label="Returned" />
                                        </ListItemButton>
                                      </>
                                    )}
                                    {value === "Returned" && (
                                      <>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e, "I am Dami");
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(true);
                                            setShowOutForDeliveryForm(false);
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                            setFormStatusText("Delivered");
                                            // setShowOutForDeliveryForm(false);
                                          }}
                                        >
                                          <CustomChip label="Delivered" />
                                        </ListItemButton>
                                        <ListItemButton
                                          onClick={(e) => {
                                            handlePopChange(e, "I am Dami");
                                            handleUpdateStatusDialogOpen();
                                            setShowDeliveredForm(false);
                                            setShowOutForDeliveryForm(true);
                                            setShowCancelledOrReturnedForm(
                                              false
                                            );
                                            setFormStatusText(
                                              "Out for delivery"
                                            );
                                            // setShowOutForDeliveryForm(false);
                                          }}
                                        >
                                          <CustomChip label="Out for delivery" />
                                        </ListItemButton>
                                      </>
                                    )}
                                  </List>
                                </Box>
                              </Popover>
                              {/* Dialog box pops out when a status change is selected */}
                              <Dialog
                                open={updateStatus}
                                onClose={handleUpdateStatusDialogClose}
                                PaperProps={{
                                  sx: {
                                    borderRadius: "16px",
                                  },
                                }}
                              >
                                <Paper
                                  sx={{
                                    width: "500px",
                                    p: 4,
                                  }}
                                >
                                  <CloseIcon
                                    sx={{
                                      display: "block",
                                      fontSize: "2.5rem",
                                      marginLeft: "auto",
                                    }}
                                    onClick={handleUpdateStatusDialogClose}
                                  />
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      fontWeight: 500,
                                    }}
                                  >
                                    Update Status - {formStatusText}
                                  </Typography>
                                  <DialogContent
                                    sx={{
                                      "&.MuiDialogContent-root": {
                                        padding: 0,
                                      },
                                    }}
                                  >
                                    <Box>
                                      {showOutForDeliveryForm && (
                                        <OutForDeliveryForm
                                          setTriggerRerender={
                                            setTriggerRerender
                                          }
                                          onClose={
                                            handleUpdateStatusDialogClose
                                          }
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                          currentID={currentID.current}
                                        />
                                      )}
                                      {/* <OutForDeliveryForm /> */}
                                      {/* {<DeliveredForm />} */}
                                      {showCancelledOrReturnedForm && (
                                        <CancelledOrReturnedForm
                                          // setTriggerRerender={
                                          //   setTriggerRerender
                                          // }
                                          onClose={
                                            handleUpdateStatusDialogClose
                                          }
                                          currentID={currentID.current}
                                          terminalSerialNumber={
                                            row.extraDetails[0].terminalSerialNo
                                          }
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                          status={formAction}
                                        />
                                      )}
                                      {showDeliveredForm && (
                                        <DeliveredForm
                                          // setTriggerRerender={
                                          //   setTriggerRerender
                                          // }
                                          onClose={
                                            handleUpdateStatusDialogClose
                                          }
                                          currentID={currentID.current}
                                          terminalSerialNumber={
                                            row.extraDetails[0].terminalSerialNo
                                          }
                                          isLoading={isLoading}
                                          setIsLoading={setIsLoading}
                                        />
                                      )}
                                    </Box>
                                  </DialogContent>
                                </Paper>
                              </Dialog>
                            </>
                          ) : value === null || value === "" ? (
                            " - "
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Row;
