import {
  Box,
  Container,
  Stack,
  useTheme,
  Typography,
  Popover,
  Paper,
  Divider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import trdLogo from '../assets/DukkaLogo.svg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../features/trdUserSlice';

function Navbar() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [trdUser, setTrdUser] = useState(
    JSON.parse(localStorage.getItem('trdUser'))
  );
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (trdUser) {
      setTimeout(() => {
        console.log(trdUser);
        setEmail(trdUser.email);
      }, [750]);
    }
  }, [trdUser]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const profilePop = open ? 'profile-popover' : undefined;

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <Container
          sx={
            {
              // border: '2px solid green',
            }
          }
        >
          <Stack
            direction='row'
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              marginTop: '30px',
            }}
          >
            <Stack direction='row' alignItems={'center'}>
              <Link
                // to='/'
                style={{
                  textDecoration: 'none',
                }}
              >
                <Box
                  component={'img'}
                  src={trdLogo}
                  alt='Terminal Request Dashboard'
                  sx={{
                    height: '50px',
                    marginRight: '20px',
                
                  }}
                />
              </Link>
              <Typography variant='h4' color='initial'>
                Terminal Request Dashboard
              </Typography>
            </Stack>
            {location.pathname === '/login' ||
            location.pathname === '/sign-up' ? (
              <></>
            ) : (
              <>
                <AccountCircleOutlinedIcon
                  sx={{
                    fontSize: '2.5rem',

                    color: 'black',
                  }}
                  onClick={handleClick}
                />
                <Popover
                  id={profilePop}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      '&.MuiPaper-root': {
                        width: '250px',
                        p: 2,
                      },
                    }}
                  >
                    <Stack>
                      <Box>
                        <AccountCircleOutlinedIcon
                          sx={{
                            fontSize: '2.5rem',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        />
                        <Typography
                          variant='body1'
                          sx={{
                            textAlign: 'center',
                            fontWeight: '700',
                            fontSize: '1.1rem',
                            my: 2,
                          }}
                        >
                          {email.length > 25
                            ? `${email.substring(0, 25)}....`
                            : email}
                        </Typography>

                        <Divider />
                        <Typography
                          variant='body1'
                          sx={{
                            fontWeight: '500',
                            my: 1,
                            color: 'red',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                          onClick={() => {
                            dispatch(logoutUser());
                            handleClose();
                          }}
                        >
                          Sign out
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Popover>
              </>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Navbar;
