import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function ProtectedRoutes({ children }) {
  const { trdUser } = useSelector((store) => store.trdUser);
  if (!trdUser) {
    return <Navigate to='/login' />;
  }
  return children;
}

export default ProtectedRoutes;
