import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SharedLayout from '../components/SharedLayout';
import Dashboard from '../pages/Dashboard';
import Error from '../pages/Error';
import Login from '../pages/Login';
import ProtectedRoutes from '../pages/ProtectedRoutes';
import SignUp from '../pages/SignUp';

function TRDRoutes() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<SharedLayout />}>
          <Route
            index
            element={
              <ProtectedRoutes>
                <Dashboard />
              </ProtectedRoutes>
            }
          />
          <Route path='login' element={<Login />} />
          {/* uncomment if you wanna add the sign up route */}
          {/* <Route path='sign-up' element={<SignUp />} /> */}
        </Route>

        <Route path='*' element={<Error />} />
      </Routes>
    </Router>
  );
}

export default TRDRoutes;
